import React, { useState } from 'react'
import { motion } from 'framer-motion'
import {AiOutlineRight} from 'react-icons/ai'
import { variants, transition } from '../utils/transitions'
import { useNavigate  } from "react-router-dom";
import './index.scss'

function Subscribe() {

  const [email, setEmail] = useState("")
  const navigate = useNavigate()

  const onEmailChange = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    navigate('/success')
  }

  return (
    <motion.div className='subscribePage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='subscribePage-content'>
          <h2>Get notified</h2>
          <p>Get your early access invite here for free when service goes live</p>





          <div>
            <form onSubmit={onFormSubmit}>
              <input type="email" placeholder="Your email" onChange={onEmailChange} value={email} required />
              <button type="submit" className='subButton'>Notify me <AiOutlineRight /></button>
            </form>
          </div>
        </div>
    </motion.div>
  )
}

export default Subscribe
import './styles/global.scss'

import {
  Routes,
  Route,
} from "react-router-dom";

import {AnimatePresence} from "framer-motion"


import Home from './components/Home';
import Subscribe from './components/Subscribe'
import Success from './components/Success'

function App() {
  return (
    <div className="App">
      <div className='background'>

      </div>
      <AnimatePresence exitBeforeEnter> 
        <Routes>
          <Route path="success" element={<Success />}/>
          <Route path="subscribe" element={<Subscribe />}/>
          <Route path="/" exact element={<Home />}/>
        </Routes>
      </AnimatePresence>
      
    </div>
    
  );
}

export default App;

import React from 'react';
import Logo from '../../assets/logo.svg';
import Button from '../partials/Button'

import { motion } from 'framer-motion';

import { variants, transition } from '../utils/transitions'

import './index.scss';

function Home() {

  return (
    <motion.div className='homePage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='homePage-logo'>
            <img src={Logo} alt="img-logo"/> 
        </div>
        <div className='homePage-welcome'>
            <h1>Coming soon</h1>
            <p>Rent your login info as service</p>
        </div>
        <div className='homePage-links'>
            <Button label='Get notified' link='/subscribe' />
        </div>
    </motion.div>
  )
}

export default Home